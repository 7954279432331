<template>
  <v-dialog :width="expense.stripe_token ? 500 : 350" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-list-item class="lessMargin" @click="fetchExpenseInforme">
        <v-list-item-icon style="margin-top: 10px">
          <v-icon small>$form</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <span>{{ $tc("informe") }} </span>
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title> {{ $tc("informe") }} </v-card-title>
      <div class="close">
        <v-tooltip bottom v-if="expense.stripe_token">
          <template v-slot:activator="{ on }">
            <v-btn
              @click="goToRecipt()"
              v-on="on"
              style="right: 56px"
              icon
              small
              ><v-icon small>$ticket</v-icon>
            </v-btn>
          </template>
          Ver recibo
        </v-tooltip>

        <v-tooltip bottom v-if="expense.stripe_token">
          <template v-slot:activator="{ on }">
            <v-btn
              style="right: 28px"
              icon
              @click="dowloadFactura()"
              small
              v-on="on"
            >
              <v-icon small> $download</v-icon>
            </v-btn>
          </template>
          {{ $t("download") }}
        </v-tooltip>
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-simple-table>
              <tr>
                <td width="120">Concepto:</td>
                <td>{{ informe.conceptos || "-" }}</td>
              </tr>
              <tr>
                <td width="120">Comentario:</td>
                <td>{{ informe.comentario || "-" }}</td>
              </tr>
            </v-simple-table>
          </v-col>
          <v-col class="py-0">
            <v-simple-table class="resume">
              <tr>
                <td width="120">Importe:</td>
                <td>{{ total }}</td>
              </tr>

              <tr v-if="expense.commission">
                <td>Comisión:</td>
                <td>
                  {{ $n(expense.commission, "currency") }}
                  <span v-if="expense.commission_payed" class="success--text"
                    >Pagado</span
                  >
                  <span v-else class="red--text">{{
                    $t("expenses.no_done")
                  }}</span>
                </td>
              </tr>

              <tr v-if="expense.commission">
                <td>Neto:</td>
                <td>
                  {{ $n(expense.cantidad - expense.commission, "currency") }}
                </td>
              </tr>
              <tr v-if="expense.stripe_token && discount">
                <td width="120">Descuento:</td>
                <td>{{ discount }}</td>
              </tr>
              <tr v-if="expense.stripe_token">
                <td>Comisión aplicacion:</td>
                <td>{{ appFee }}</td>
              </tr>
              <tr v-if="expense.stripe_token">
                <td>Reembolso:</td>
                <td>{{ refund }}</td>
              </tr>
              <tr v-if="expense.stripe_token">
                <td>Neto:</td>
                <td>{{ neto }}</td>
              </tr>
            </v-simple-table>
          </v-col>
          <v-col class="py-0" cols="6" v-if="expense.stripe_token">
            <v-timeline align-top dense>
              <v-timeline-item
                x-small
                v-for="(h, i) in informe.pi.history"
                :key="i"
                >{{ $d(new Date(h.date * 1000), "date2digits") }} -
                {{ $tc(h.event) }}</v-timeline-item
              >
            </v-timeline>
          </v-col>
          <v-col cols="12" v-if="expense.stripe_token"> </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  name: "ExpenseRefund",
  props: ["expense"],
  data() {
    return {
      dialog: false,
      informe: { pi: {} },
    };
  },
  components: {
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },
  computed: {
    total() {
      return this.informe.pi && this.informe.pi.total
        ? this.$n(this.informe.pi.total, "currency")
        : this.$n(this.expense.cantidad, "currency");
    },
    appFee() {
      return this.$n(this.informe.pi.qink_fee, "currency") || "-";
    },
    psFee() {
      return this.$n(this.informe.pi.ps_fee, "currency") || "-";
    },
    refund() {
      return this.$n(this.informe.pi.refund, "currency") || null;
    },
    neto() {
      return this.$n(
        this.informe.pi.total -
          (this.informe.pi.refund +
            this.informe.pi.qink_fee +
            this.informe.pi.discount),
        "currency"
      );
    },
    discount() {
      if (this.informe.pi.discount)
        return this.$n(this.informe.pi.discount, "currency");
      return null;
    },
  },
  methods: {
    ...mapActions("expenses", ["getExpenseInforme"]),
    fetchExpenseInforme() {
      this.getExpenseInforme(this.expense.id).then((data) => {
        this.informe = data;
        this.dialog = true;
      });
    },
    goToRecipt() {
      window.open(this.informe.pi.recipt_url, "_blank");
    },
    ...mapActions("plans", ["getInvoice"]),
    dowloadFactura() {
      this.getInvoice(this.informe.pi.invoice_id).then((data) => {
        //console.log("return");
        //console.log(data);
        axios.get(data.url, { responseType: "blob" }).then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Factura - " + id + ".pdf";
          link.click();
          URL.revokeObjectURL(link.href);
        });
      });
    },
  },
};
</script>

<style scoped lang="sass">
.v-data-table
  tr
    td:first-child
      color: var(--v-primary-base)
.lessMargin
  height: 30px
  cursor: pointer
  .v-list-item__icon
    margin-right: 5px !important

  .v-icon, .svg-icon
    margin-top: -3px
    color: var(--v-gris1-lighten2) !important
.resume
  tr > td:last-child

    //text-align: right
</style>